import { useState, useEffect } from 'react';

import './NavBar.css';

const NavBar = ({ onNavClick, homeRef, bookingsRef, portfolioRef }) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      // if scroll down hide the navbar
      setShow(false);
    } else {
      // if scroll up show the navbar
      setShow(true);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);
  return (
    <div className={`NavBackground active ${show === false && 'hidden'}`}>
      <div className='NavBar'>
        <div className='NavLogo'>
          <p className='LogoText'>
            KLIMENTY VA<span className='Mirrored'>SS</span>ILIEV
          </p>
        </div>
        <ul className='NavMenu'>
          <li
            className='NavItem'
            onClick={() => {
              onNavClick(homeRef.current);
            }}
          >
            Home
          </li>
          <li
            className='NavItem'
            onClick={() => {
              onNavClick(bookingsRef.current);
            }}
          >
            Bookings
          </li>
          <li
            className='NavItem'
            onClick={() => {
              onNavClick(portfolioRef.current);
            }}
          >
            Portfolio
          </li>
          <li className='NavItem' onClick={onNavClick}>
            Shop
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
