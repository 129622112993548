import { useState, useRef } from 'react';
import { validateForm, validateField } from '../../validation/validation.ts';
import { fileToBase64 } from '../../api/inquiryApi.js';
import mail from '../../assets/send.svg';
import send from '../../assets/mail.svg';
import Textarea from './Textarea';
import ToggleGroup from './ToggleGroup';
import Textfield from './Textfield';
import Checkbox from './Checkbox';
import FileInput from './FileInput';

import './Form.css';

const Form = ({ sendInquiry }) => {
  let initialBookingRequest = {
    firstName: null,
    lastName: null,
    email: null,
    bodyPlacement: null,
    sideOfBody: null,
    projectInformation: null,
    preferredLocation: null,
    width: null,
    height: null,
    bodyPlacementImage: null,
    preferredMonth: null,
    confirmConditions: false,
    confirmDeposit: false,
    referenceImages: [],
  };

  const bodyPlacementOptions = [
    { value: 'Arm', label: 'Arm' },
    { value: 'Ben', label: 'Leg' },
    { value: 'Bröst', label: 'Chest' },
    { value: 'Rygg', label: 'Back' },
  ];

  const sideOfBodyOptions = [
    { value: 'Höger', label: 'Right' },
    { value: 'Vänster', label: 'Left' },
  ];

  const preferredLocationOptions = [
    { value: 'Sion', label: 'Sion', isExpandable: false },
    { value: 'Stockholm', label: 'Stockholm', isExpandable: false },
    { value: 'Guest', label: 'Guest', isExpandable: true },
  ];

  const preferredMonthOptions = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },
  ];

  const [bookingRequest, setBookingRequest] = useState(initialBookingRequest);
  const [formValidation, setFormValidation] = useState({
    ...initialBookingRequest,
  });
  const clearStateFunc = useRef(null);

  const onChangeUseState = (evt) => {
    const { field, value } = evt;

    handleValidation(field, value);
    setBookingRequest({ ...bookingRequest, [field]: value });
  };

  const handleValidation = (field, value) => {
    let errorMessage = validateField(field, value);

    if (errorMessage === null) {
      const { [field]: _, ...updatedValidation } = formValidation;
      setFormValidation(updatedValidation);
    } else {
      const updatedValidation = { ...formValidation, [field]: errorMessage };
      setFormValidation(updatedValidation);
    }
  };

  const handleSendInquiry = async () => {
    let validatedFields = validateForm(bookingRequest);
    setFormValidation(validatedFields);

    if (Object.keys(formValidation).length === 0) {
      const references = await Promise.all(
        bookingRequest.referenceImages.map((file) => fileToBase64(file))
      );
      const bodyPlacement = await fileToBase64(
        bookingRequest.bodyPlacementImage[0]
      );

      const bookingsRequestFormatted = {
        ...bookingRequest,
        referenceImages: references,
        bodyPlacementImage: bodyPlacement,
      };

      await sendInquiry(bookingsRequestFormatted);
    }
  };

  return (
    <div className='Form'>
      <div className='Form-header'>
        <h2>
          <object data={mail} aria-label='Alternative icon text'>
            Alternative icon text
          </object>
          <span>Tattoinquiry</span>
        </h2>
      </div>
      <Textfield
        label='First name'
        assignedField='firstName'
        placeHolder='First name'
        error={formValidation.firstName}
        childToParent={onChangeUseState}
      />
      <Textfield
        label='Last name'
        assignedField='lastName'
        placeHolder='Last name'
        error={formValidation.lastName}
        childToParent={onChangeUseState}
      />
      <Textfield
        label='E-mail'
        assignedField='email'
        placeHolder='Email'
        error={formValidation.email}
        childToParent={onChangeUseState}
      />
      <Textarea
        label='Project description'
        assignedField='projectInformation'
        error={formValidation.projectInformation}
        childToParent={onChangeUseState}
      />
      <FileInput
        label='Reference images'
        assignedField='referenceImages'
        error={formValidation.referenceImages}
        childToParent={onChangeUseState}
        multiple={true}
      />
      <ToggleGroup
        id='place'
        label='Placement'
        assignedField='bodyPlacement'
        error={formValidation.bodyPlacement}
        nullable={false}
        options={bodyPlacementOptions}
        childToParent={onChangeUseState}
      />
      <ToggleGroup
        id='side'
        label='Side of body'
        assignedField='sideOfBody'
        nullable={true}
        options={sideOfBodyOptions}
        childToParent={onChangeUseState}
      />
      <div className='Size-fields'>
        <Textfield
          label='Width'
          assignedField='width'
          error={formValidation.width}
          type='number'
          unit='cm'
          childToParent={onChangeUseState}
        />
        <Textfield
          label='Height'
          assignedField='height'
          error={formValidation.height}
          type='number'
          unit='cm'
          childToParent={onChangeUseState}
        />
      </div>
      <FileInput
        label='Body placement image'
        assignedField='bodyPlacementImage'
        error={formValidation.bodyPlacementImage}
        childToParent={onChangeUseState}
        multiple={false}
      />
      <ToggleGroup
        id='loaction'
        label='Preferred location'
        assignedField='preferredLocation'
        nullable={false}
        error={formValidation.preferredLocation}
        options={preferredLocationOptions}
        childToParent={onChangeUseState}
        columns={3}
      />
      <ToggleGroup
        id='month'
        label='Preferred month of appointment'
        assignedField='preferredMonth'
        nullable={false}
        error={formValidation.preferredMonth}
        options={preferredMonthOptions}
        childToParent={onChangeUseState}
        columns={3}
      />
      <Checkbox
        label='I agree with the conditions'
        assignedField='confirmConditions'
        error={formValidation.confirmConditions}
        childToParent={onChangeUseState}
        clearStateFunc={clearStateFunc}
      />
      <Checkbox
        label='I am aware that the deposit is non-refundable'
        assignedField='confirmDeposit'
        error={formValidation.confirmDeposit}
        childToParent={onChangeUseState}
      />
      <button
        className='Submit-button'
        type='button'
        onClick={handleSendInquiry}
      >
        <img src={send} alt='icon' />
        Send inquiry
      </button>
    </div>
  );
};

export default Form;
