import { useState } from 'react';

import './Textarea.css';

const Textarea = ({
  label,
  assignedField,
  placeholder,
  childToParent,
  error,
}) => {
  let [value, setValue] = useState('');

  const onInput = (e) => {
    setValue((value) => (value = e.target.value));
    childToParent({ field: assignedField, value: e.target.value });
  };

  return (
    <div className='Textarea'>
      <label className='Textarea-label'>{label}</label>
      <div className='Textarea-area-wrapper'>
        <textarea
          className={`Textarea-input ${
            error !== null && error !== undefined ? 'error' : ''
          }`}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onInput(e)}
        ></textarea>
        {error !== null && <span className='error-message'>{error}</span>}
      </div>
    </div>
  );
};

export default Textarea;
