import { useState, useEffect } from 'react';
import { FaRegCircleXmark } from 'react-icons/fa6';
import './Thumbnail.css';

const Thumbnail = ({ image, removeFile }) => {
  const fileSize = () => {
    let sizeInKb = image.size / 1024;

    if (sizeInKb > 1024) return `${(sizeInKb / 1024).toFixed(2)} MB`;
    else return `${sizeInKb.toFixed(2)} KB`;
  };

  const onRemoveClick = () => {
    removeFile(image);
  };

  return (
    <div className='Thumbnail'>
      <div className='Thumbnail-remove'>
        <FaRegCircleXmark
          className='Thumbnail-remove__icon'
          onClick={onRemoveClick}
        />
      </div>
      <div className='Thumbnail-wrapper'>
        <img
          className='Thumbnail-image'
          src={URL.createObjectURL(image)}
          alt='thumbnail'
        />
      </div>
      <div className='Thumbnail-size'>
        <span className='Thumbnail-size__text'>{fileSize()}</span>
      </div>
    </div>
  );
};

export default Thumbnail;
