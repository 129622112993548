import './Card.css';

const Card = ({ imageSrc, header, body, hyperlink }) => {
  const getHeader = () => {
    if (hyperlink !== null) {
      return (
        <a href={hyperlink} target='_blank' rel='noreferrer'>
          {header}
        </a>
      );
    } else {
      return { header };
    }
  };
  return (
    <div className='card'>
      <div className='card-body'>
        <h4>{getHeader()}</h4>
        <p>{body}</p>
      </div>
      <div className='card-image'>
        <img src={imageSrc} alt='' />
      </div>
    </div>
  );
};

export default Card;
