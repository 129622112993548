import Background from './components/background/Background';
import Bookings from './components/bookings/Bookings';
import Portfolio from './components/portfolio/Portfolio';
import NavBar from './components/navBar/NavBar';
import BurgerNav from './components/navBar/BurgerNav';
import ScrollButton from './components/scrollbutton/ScrollButton';
import Footer from './components/footer/Footer';
import { useState, useEffect, useRef } from 'react';
import './App.css';

const App = () => {
  const [heroCount, setHeroCount] = useState(0);
  const isSmallScreen = window.matchMedia('(max-width: 576px)');
  let homeRef = useRef(null);
  let bookingsRef = useRef(null);
  let portfolioRef = useRef(null);

  useEffect(() => {
    const timerId = setInterval(() => {
      setHeroCount((heroCount) =>
        heroCount === 2 ? (heroCount = 0) : heroCount + 1
      );
    }, 3000);

    return () => clearInterval(timerId);
  }, []);

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  const renderNavBar = () => {
    if (!isSmallScreen.matches) {
      return (
        <NavBar
          onNavClick={scrollToSection}
          homeRef={homeRef}
          bookingsRef={bookingsRef}
          portfolioRef={portfolioRef}
        />
      );
    }
  };

  const renderHamburgerMenu = () => {
    if (isSmallScreen.matches) {
      return (
        <BurgerNav
          onNavClick={scrollToSection}
          homeRef={homeRef}
          bookingsRef={bookingsRef}
          portfolioRef={portfolioRef}
        />
      );
    }
  };

  return (
    <div className='App'>
      {renderHamburgerMenu()}
      <section className='home' ref={homeRef}>
        <div className='Section-wrapper'>
          {renderNavBar()}
          <Background heroCount={heroCount} />
        </div>
      </section>
      <section className='bookings' ref={bookingsRef}>
        <div className='Section-wrapper'>
          <Bookings />
        </div>
      </section>
      <section className='portfolio' ref={portfolioRef}>
        <div className='Section-wrapper'>
          <Portfolio />
        </div>
      </section>
      <footer>
        <Footer
          onNavClick={scrollToSection}
          homeRef={homeRef}
          bookingsRef={bookingsRef}
          portfolioRef={portfolioRef}
        />
      </footer>
      <ScrollButton />
    </div>
  );
};

export default App;
