import { useState } from 'react';
import { sendInquiry } from '../../api/inquiryApi.js';
import Form from '../form/Form';
import Loader from '../loader/Loader';

import './Bookings.css';

const Bookings = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const triggerInquiry = async (body) => {
    setLoading(true);

    let response = await sendInquiry(body);

    if (response.statusCode === 200) {
      setResponse({
        statusCode: 200,
        message: `Request has been sent, someone will get in contact with you shortly.`,
      });
    } else {
      setResponse({
        statusCode: response.statusCode,
        message:
          'Something went wrong, please send a email to tattooklimenty@gmail.com with your request.',
      });
    }

    setLoading(false);

    setTimeout(() => {
      setResponse(null);
    }, 5000);
  };

  return (
    <div className='bookings-view'>
      <div className='Form-wrapper'>
        <Form sendInquiry={triggerInquiry} />
      </div>
      <div className='Terms-and-conditions-wrapper'>
        <h2 className='Terms-and-conditions__header'>Terms & Conditions</h2>
        <h4>
          Read all info below before filling in your request - there will be no
          surprise if you read it all.
        </h4>
        <div className='Terms-and-conditions__body'>
          <strong>ROADMAP</strong>
          <ul>
            <li>
              <strong>STEP 1:</strong> Read the conditions
            </li>
            <li>
              <strong>STEP 2:</strong> Approval of your project
            </li>
            <li>
              <strong>STEP 3:</strong> Booking confirmation
            </li>
            <li>
              <strong>STEP 4:</strong> Design making{' '}
            </li>
            <li>
              <strong>STEP 5:</strong> Tattoo day
            </li>
            <li>
              <strong>Tattoo fee</strong>
            </li>
          </ul>
          <h4>
            Unless stated otherwise, you will be getting your tattoo in: CNCRT40
            Valhallavägen 40 Stockholm, Sweden.
          </h4>
          <p>
            The booking process usually takes 2 to 5 days but it can take
            longer. We respond to everyone.
          </p>
          <h4>Step 1: Initial step</h4>
          <p>Read the conditions and fill out the booking form.</p>

          <h4>Step 2: Project Approval</h4>
          <p>
            Greetings, Klimenty here. I tattoo only three days a week to
            maintain inspiration, focus, and a work-life balance. I cannot
            accept all requests. If your project isn't chosen, it means either
            it doesn't match my style or I've chosen other projects that
            resonate more with me. Note:
          </p>
          <ul>
            <li>I don't work on old tattoos by other artists.</li>
            <li>The tattoo area must be a blank canvas.</li>
            <li>No cover-ups.</li>
          </ul>
          <p>
            If you change your project's core idea, it needs reapproval. You may
            lose your deposit and appointment if we don't agree on the new idea
            or if you take it elsewhere.
          </p>
          <p>
            If selected, you’ll receive date options via email. If none work,
            please contact us.
          </p>

          <h4>Step 3: Booking Confirmation</h4>
          <p>
            Once your project is approved and a date is set, send a 250€ deposit
            within 24 hours to secure your spot. My assistant will email you the
            bank details. The deposit is non-refundable and deducted from the
            tattoo fee.
          </p>

          <h4>Step 4: Design Creation</h4>
          <p>
            I’ll contact you 1-2 days before the tattoo day to finalize your
            design and ask for more references. Creative freedom is crucial;
            I'll incorporate your ideas. If you want a freeform piece, mention
            it below. I’ll send the finished design the day before the tattoo
            for feedback. Minor adjustments can be made to ensure no stress on
            the tattoo day. The design is for you alone, so avoid sharing it for
            others’ opinions. Projects are handled one at a time, so please be
            patient.
          </p>

          <h4>Step 5: Tattoo Day</h4>
          <p>
            Appointments start at 10 am and end between 4-7 pm, depending on the
            work required. I finish what I start and ensure the design matches
            the booked time to complete it within human limits, barring
            unforeseen events.
          </p>

          <ul>
            <li>
              Mini consultation when the day starts to make sure you are happy
            </li>
            <li>Minor adjustments on the design are usually done on the day</li>
            <li>Coffee, soft drinks & lunch are provided </li>
            <li>Aftercare instructions and ointment are provided</li>
          </ul>

          <h4>Tattoo fee</h4>
          <p>Prices apply for day sessions.</p>
          <ul>
            <li>Shorter days: 1000€</li>
            <li>Longer days: 1500€</li>
          </ul>
          <p>Size, amount of details and placement may affect the price.</p>
        </div>
      </div>
      <Loader response={response} loading={loading} />
    </div>
  );
};

export default Bookings;
