import React, { useState, useRef } from 'react';
import Textfield from './Textfield';
import './ToggleGroup.css';

const ToggleGroup = ({
  id,
  label,
  assignedField,
  options,
  childToParent,
  nullable,
  error,
  columns,
}) => {
  const [selected, setSelected] = useState(null);
  const [expandableValue, setExpandableValue] = useState('');
  const clearStateFunc = useRef(null);

  const onInput = (e) => {
    hideExpandables();
    if (nullable === true && e.target.value === selected) {
      setSelected(null);
    } else {
      setSelected(e.target.value);
    }
    childToParent({ field: assignedField, value: e.target.value });
  };

  const onExpand = (e, i) => {
    hideExpandables();
    setSelected(e.target.value);
    childToParent({ field: assignedField, value: null });
    document
      .getElementById(`expandableText-${id}-${i}`)
      .classList.add('expanded');
  };

  const onChangeExpandableValue = (e) => {
    setExpandableValue(e.value);
    childToParent({ field: e.field, value: e.value });
  };

  const hideExpandables = () => {
    let expandables = document.getElementsByClassName(
      `expandableText-${id} expanded`
    );
    setExpandableValue('');
    if (clearStateFunc.current) {
      clearStateFunc.current();
    }
    for (let expandable of expandables) {
      if (expandable.classList && expandable.classList.contains('expanded'))
        expandable.classList.remove('expanded');
    }
  };

  const showErrorMessage = () => {
    return nullable === false && error !== null && error !== undefined;
  };

  const getColumnClass = () => {
    if (columns === 3) return 'Togglegroup-option-three';
    else if (columns === 4) return 'Togglegroup-option-four';
  };

  return (
    <div className='Togglegroup'>
      <label className='Togglegroup-label'>{label}</label>
      <div
        className={`Togglegroup-wrapper ${showErrorMessage() ? 'error' : ''}`}
      >
        {options.map(({ value, label, isExpandable }, i) => (
          <React.Fragment key={`Rf${i}`}>
            <button
              className={
                selected === value
                  ? 'Togglegroup-option Togglegroup-option--active ' +
                    getColumnClass()
                  : 'Togglegroup-option ' + getColumnClass()
              }
              key={i}
              value={value}
              onClick={!isExpandable ? onInput : (e) => onExpand(e, i)}
            >
              {label}
            </button>
            {isExpandable && (
              <div
                className={`expandableText-${id} expandableText`}
                id={`expandableText-${id}-${i}`}
              >
                <Textfield
                  assignedField={assignedField}
                  value={expandableValue}
                  label='Desired location'
                  childToParent={onChangeExpandableValue}
                  clearStateFunc={clearStateFunc}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {showErrorMessage() && <span className='error-message'>{error}</span>}
    </div>
  );
};

export default ToggleGroup;
