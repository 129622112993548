const validateForm = (form) => {
  let validatedFields = {};

  for (const [key, value] of Object.entries(form)) {
    let validationMsg = validateField(key, value);

    if (validationMsg !== null)
      validatedFields = { ...validatedFields, [key]: validationMsg };
  }

  return validatedFields;
};

const validateField = (key, value) => {
  if (value === null && value?.match(/^ *$/) !== null) {
    return `This is a required field`;
  }

  if (
    (key === 'confirmConditions' || key === 'confirmDeposit') &&
    value === false
  ) {
    return 'You have to confirm this';
  }

  if (key === 'email' && value !== null && !validateEmail(value)) {
    return 'The provided email is in wrong format.';
  }

  if (key === 'referenceImages' || key === 'bodyPlacementImage') {
    if (!validateFileSize(key, value)) {
      return `Total size of attachments can't exceed 25 MB.`;
    }
  }

  return null;
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateFileSize = (key, files) => {
  if (files && files.length > 0) {
    let accumulatedFileSizeMb = 0;

    for (let i = 0; i < files.length; i++) {
      accumulatedFileSizeMb += files[i].size / (1024 * 1024);

      if (accumulatedFileSizeMb > 25) {
        return false;
      }
    }
  }

  return true;
};

export { validateForm, validateField, validateFileSize };
