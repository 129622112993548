import './BurgerNav.css';
import { useState } from 'react';

const BurgerNav = ({ onNavClick, ...refs }) => {
  const [open, setOpen] = useState(false);

  const handleNavClick = (ref) => {
    setOpen(!open);
    if (ref !== null && ref !== undefined) onNavClick(ref.current);
  };

  return (
    <nav role='navigation'>
      <div className={`burgerToggle`}>
        <input
          className={open ? 'open' : ''}
          type='checkbox'
          defaultChecked={open}
          onChange={() => setOpen((open) => !open)}
        />

        <span></span>
        <span></span>
        <span></span>

        <ul className={`navMenu ${!open ? 'closed' : ''}`}>
          <li
            className='navItem'
            onClick={() => {
              handleNavClick(refs.homeRef);
            }}
          >
            Home
          </li>
          <li
            className='navItem'
            onClick={() => {
              handleNavClick(refs.bookingsRef);
            }}
          >
            Bookings
          </li>
          <li
            className='navItem'
            onClick={() => {
              handleNavClick(refs.porfolioRef);
            }}
          >
            Portfolio
          </li>
          <li className='navItem'>Shop</li>
        </ul>
      </div>
    </nav>
  );
};

export default BurgerNav;
