import { useState, useEffect, useRef, useCallback } from 'react';
import { FaUpload } from 'react-icons/fa6';
import Thumbnail from './Thumbnail';
import './FileInput.css';

const FileInput = ({
  label,
  assignedField,
  placeHolder,
  childToParent,
  error,
  multiple,
  clearStateFunc,
}) => {
  const [files, setFiles] = useState([]);
  const inputFileRef = useRef(null);

  const showValidation = () => {
    return error != null;
  };

  const onInput = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (multiple === true) {
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      childToParent({
        field: assignedField,
        value: [...files, ...selectedFiles],
      });
    } else {
      setFiles([selectedFiles[0]]);
      childToParent({ field: assignedField, value: [selectedFiles[0]] });
    }
  };

  const clearState = () => {
    setFiles([]);
  };

  const triggerBrowseFiles = () => {
    inputFileRef.current.click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      if (multiple === true) {
        const newFiles = Array.from(droppedFiles);
        const newImages = newFiles.filter((f) => f.type.includes('image'));
        setFiles((prevFiles) => [...prevFiles, ...newImages]);
        childToParent({
          field: assignedField,
          value: [...files, ...newImages],
        });
      } else {
        let firstImage = droppedFiles[0];

        setFiles([firstImage]);
        childToParent({ field: assignedField, value: [firstImage] });
      }
    }
  };

  const removeFile = useCallback((fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  }, []);

  useEffect(() => {
    if (clearStateFunc) {
      clearStateFunc.current = clearState;
    }
  }, [clearStateFunc]);

  return (
    <div className='FileInput'>
      <label className='FileInput-label'>{label}</label>
      <div className='FileInput-input-wrapper'>
        <div
          className='FileInput-input__drag-n-drop-container'
          onClick={triggerBrowseFiles}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='FileInput-heading__wrapper'>
            <div className='FileInput-heading__text'>
              <p className='FileInput-heading__paragraph'>{`Drop image${
                multiple === true ? 's' : ''
              } here`}</p>
              <p className='FileInput-heading__alternative'>{`or browse image${
                multiple === true ? 's' : ''
              }`}</p>
              <FaUpload
                className='FileInput-heading__icon klimentyv-icon'
                color='rgba(211, 211, 211, 0.4)'
              />
            </div>
            <div className='FilInput-thumbnail__container'></div>
          </div>
        </div>
        {files.length > 0 && (
          <div className='FileInput-preview'>
            {files.map((file, i) => (
              <Thumbnail image={file} key={i} removeFile={removeFile} />
            ))}
          </div>
        )}
        <input
          className='FileInput-input'
          type='file'
          ref={inputFileRef}
          multiple={multiple}
          onChange={onInput}
          accept='.jpg,.jpeg,.png'
        />
      </div>
      {showValidation() && <span className='error-message'>{error}</span>}
    </div>
  );
};

export default FileInput;
