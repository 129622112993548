import PortfolioCard from './PortfolioCard';

import './Portfolio.css';

import tattOne from '../../assets/tattOne.jpg';
import tattTwo from '../../assets/tattTwo.jpg';
import tattThree from '../../assets/tattThree.jpg';
import tattFour from '../../assets/tattFour.jpg';
import tattFive from '../../assets/tattFive.jpg';
import tattSix from '../../assets/tattSix.jpg';

const Portfolio = () => {
  const images = [tattOne, tattTwo, tattThree, tattFour, tattFive, tattSix];

  return (
    <div className='Portfolio'>
      <div className='Portfolio-track'>
        {images.map((img, i) => {
          return (
            <PortfolioCard
              imgSrc={img}
              imgAlt={`porfolioCard-${i}`}
              key={`0${i}`}
            />
          );
        })}
        {images.map((img, i) => {
          return (
            <PortfolioCard
              imgSrc={img}
              imgAlt={`porfolioCard-${i}`}
              key={`1${i}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Portfolio;
