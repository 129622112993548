import { useState } from 'react';

import './Checkbox.css';

const Checkbox = ({ label, assignedField, childToParent, error }) => {
  const [value, setValue] = useState(false);
  const showValidation = () => {
    if (error === null || error === undefined) return false;

    return true;
  };

  const onInput = (evt) => {
    setValue((val) => (val = !value));
    childToParent({ field: assignedField, value: !value });
  };

  return (
    <div className='Checkbox'>
      <div className='Checkbox-wrapper'>
        <input type='checkbox' checked={value} onChange={(e) => onInput(e)} />
        <label className='Checkbox-label'>{label}</label>
      </div>
      {showValidation() && <span className='error-message'>{error}</span>}
    </div>
  );
};

export default Checkbox;
