import React, { useState } from 'react';
import arrowUp from '../../assets/arrowUp.svg';

import './ScrollButton.css';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className={`Scrollbutton-wrapper ${visible !== true && 'hidden'}`}>
      <button className='Scrollbutton' onClick={scrollToTop}>
        <span onClick={scrollToTop}>
          <img src={arrowUp} alt='scrollbuttonIcon' />
        </span>
      </button>
    </div>
  );
};

export default ScrollButton;
