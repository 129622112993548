import './Background.css';

import hero from '../../assets/klimentyv1.jpg';
import heroTablet from '../../assets/klimentyv1-tablet.jpg';
import heroMobile from '../../assets/klimentyv1-mobile.jpg';

const Background = ({ heroCount }) => {
  const isSmallDevice = window.matchMedia('(max-width: 480px)');
  const isTabletDevice = window.matchMedia(
    '(min-width: 481px) and (max-width: 1080px)'
  );

  if (isSmallDevice.matches) {
    return <img src={heroMobile} className='background' alt='heroMobile' />;
  } else if (isTabletDevice.matches) {
    return <img src={heroTablet} className='background' alt='heroTablet' />;
  } else {
    return <img src={hero} className='background' alt='heroDesktop' />;
  }
};

export default Background;
