import { useState, useEffect } from 'react';
import './Textfield.css';

const Textfield = ({
  label,
  assignedField,
  placeholder,
  childToParent,
  unit = null,
  type,
  error,
  clearStateFunc,
}) => {
  const [value, setValue] = useState('');

  const onInput = (e) => {
    setValue(e.target.value);
    childToParent({ field: assignedField, value: e.target.value });
  };

  const getInputType = () => {
    if (type === null) return 'text';
    else if (type === 'number') return 'number';
    else return 'text';
  };

  const showValidation = () => {
    return error !== null && error !== undefined;
  };

  const clearState = () => {
    setValue('');
  };

  useEffect(() => {
    if (clearStateFunc) {
      clearStateFunc.current = clearState;
    }
  }, [clearStateFunc]);

  return (
    <div className='Textfield'>
      <label className='Textfield-label'>{label}</label>
      <div
        className={
          unit !== null
            ? 'Textfield-input-wrapper__unit'
            : 'Textfield-input-wrapper'
        }
      >
        <input
          className={
            unit !== null
              ? 'Textfield-input__unit-value ' +
                (showValidation() ? 'error' : '')
              : 'Textfield-input ' + (showValidation() ? 'error' : '')
          }
          type={getInputType()}
          value={value}
          min={0}
          placeholder={placeholder}
          onChange={onInput}
        />
        {unit !== null && <span className='Textfield-input-unit'>{unit}</span>}
      </div>
      {showValidation() && <span className='error-message'>{error}</span>}
    </div>
  );
};

export default Textfield;
