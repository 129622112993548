import Card from './Card';
import './Footer.css';

import kv from '../../assets/kv.png';
import cncrt40 from '../../assets/cncrt40.png';
import myu from '../../assets/myu.png';
import instagram from '../../assets/instagram.svg';

const Footer = ({ onNavClick, homeRef, bookingsRef, portfolioRef }) => {
  const isSmallDevice = window.matchMedia('(max-width: 576px)');

  const getCncrtBody = () => {
    return 'Valhallavägen 40 \n 114 22 Stockholm \n Sweden';
  };

  const getMyuBody = () => {
    return "Rte de l'Aéroport 10 \n 1950 Sion \n Switzerland";
  };

  const instagramUrl = () => {
    return 'https://www.instagram.com/klimentyv?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==';
  };

  const renderCard = () => {
    if (!isSmallDevice.matches) {
      return (
        <div className='footer-studios'>
          <Card
            imageSrc={cncrt40}
            header={'CNCRT40'}
            body={getCncrtBody()}
            hyperlink={'https://cncrt40.com/'}
          />
          {/* <Card imageSrc={myu} header={'MYU'} body={getMyuBody()} /> */}
        </div>
      );
    }
  };

  return (
    <div className='footer-view'>
      {renderCard()}
      <div>
        <img src={kv} alt='kv-logo' />
      </div>
      <div>
        <ul className='footer-view-navigation'>
          <li
            className='footer-menu-item'
            onClick={() => {
              onNavClick(homeRef.current);
            }}
          >
            Home
          </li>
          <li
            className='footer-menu-item'
            onClick={() => {
              onNavClick(bookingsRef.current);
            }}
          >
            Bookings
          </li>
          <li
            className='footer-menu-item'
            onClick={() => {
              onNavClick(portfolioRef.current);
            }}
          >
            Portfolio
          </li>
        </ul>
      </div>
      <div className='footer-bottom'>
        <p>Klimenty Vassiliev © 2024 - Stockholm</p>
        <ul className='socials'>
          <li>
            <a href={instagramUrl()} target='_blank' rel='norefferrer'>
              <img src={instagram} alt='' />
            </a>
            {/* <img src={instagram} alt='' onClick={goToInstagram()} /> */}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
