import axios from 'axios';

const baseUrl = () => {
  return window.location.href.includes('localhost')
    ? 'http://localhost:3001/api'
    : 'https://www.klimenty.com/api';
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const sendInquiry = async (body) => {
  let response;

  await axios
    .post(`${baseUrl()}/inquiry`, {
      body: body,
    })
    .then((res) => {
      response = {
        statusCode: 200,
        response: res,
      };
    })
    .catch((err) => {
      response = {
        status: err.statusCode,
        message: `Request failed ${err.message}`,
      };
    });

  return response;
};

export { sendInquiry, fileToBase64 };
