// import React, { useState } from 'react';
import './Loader.css';

const Loader = ({ response, loading }) => {
  return (
    <div
      className={`Loader ${
        loading !== true && response == null ? 'hidden' : ''
      }`}
      id='modal-loading'
      data-backdrop='static'
    >
      <div className='modal-dialog modal-sm'>
        <div className='modal-content'>
          <div className='modal-body text-center'>
            <div
              className={`loading-spinner ${loading === false && 'hidden'}`}
            ></div>
            <div
              className={`loader-message ${
                loading === true && 'loader-message__padding'
              }`}
            >
              <h4>{response?.message ?? 'Sending request..'}</h4>
              {response?.message == null && (
                <p>This may take a while uploading the images, please wait.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
